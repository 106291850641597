<template>
	<div class="grid">
		<div class="col-12 md:col-6 lg:col-2">
			<h2>CARTERA</h2>
			<!-- <Button icon="pi pi-file-pdf" class="p-button-raised mb-2" @click="showSuccess(customer,index)"></Button><br>
			<Button icon="pi pi-undo" class="p-button-raised" label="Limpiar tabla" @click="cleartable"></Button> -->
			<!-- <div class="col-12 md:col-12 xl:col-12"> -->
			<!-- <template v-if="selectgenerador!=null"> -->
			<label for="email1" class="block text-900 font-medium mb-2">Generador</label>
			<div class="p-inputgroup">
				<Multiselect v-model="selectgenerador" placeholder="Seleccione Generador" :max="-1" valueProp="documento" :options="generadores" :searchable="true" label="nombrecompleto" track-by="nombrecompleto" @click="traerindicadores(selectgenerador)"/>
			</div>
			<!-- </template> -->
                    <!-- </div> -->
		</div>
		<!-- <template v-if="selectgenerador!=null"> -->
		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 purple-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(1)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">SIN VENCER</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-large">{{sin_vencer}} - <br>${{sin_vencer_valor}}</span>
						<!-- <span class="overview-status p-1 fs-small">85 Responded</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData8" type="line" :data="overviewChartData8" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 blue-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(2)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">0 - 30 DIAS</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-large">{{cuantos_0a30}} - <br>${{cuantos_0a30_valor}}</span>
						<!-- <span class="overview-status p-1 fs-small">85 Responded</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData8" type="line" :data="overviewChartData8" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 green-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(3)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">31 - 60 DIAS</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-large">{{cuantos_31a60}} - <br>${{cuantos_31a60_valor}}</span>
						<!-- <span class="overview-status p-1 fs-small">1420 Completed</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData5" type="line" :data="overviewChartData5" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 orange-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(4)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">61 - 90 DIAS</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-large">{{cuantos_61a90}} - <br>${{cuantos_61a90_valor}}</span>
						<!-- <span class="overview-status p-1 fs-small">$9,640 Income</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData6" type="line" :data="overviewChartData6" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 pink-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(5)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">MAYOR A 90 DIAS</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-large">{{cuantos_mas_90}} - <br>${{cuantos_mas_90_valor}}</span>
						<!-- <span class="overview-status p-1 fs-small">25402 Registered</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData7" type="line" :data="overviewChartData7" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>
		<!-- </template> -->
		<!-- <template v-else>
			<div class="col-12 md:col-6 lg:col-10">
				<label for="email1" class="block text-900 font-medium mb-2">Generador</label>
				<div class="p-inputgroup">
					<Multiselect v-model="selectgenerador" placeholder="Seleccione Generador" :max="-1" valueProp="documento" :options="generadores" :searchable="true" label="nombrecompleto" track-by="nombrecompleto" @click="traerindicadores(selectgenerador)"/>
				</div>
			</div>
		</template> -->
		<template v-if="loader==true">
			<div class="col-12 lg:col-12 flex justify-content-center">
				<i class="pi pi-spin pi-spinner justify-content-center" style="font-size: 3rem"></i>
			</div>
		</template>
		<div class="col-12 lg:col-12">
		<DataTable ref="pagination" :value="detalle_cartera" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="3" @paginate="onChangePage"
							:paginatorTemplate="'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'"
							:currentPageReportTemplate="'Showing '+pagination.from+' to '+pagination.to+' of '+pagination.total+' customers'" responsiveLayout="scroll">
					<template #header>
					</template>

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<Column field="sucursal_origina_descripcion" header="Sucursal" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{slotProps.data.sucursal_origina_descripcion}}
						</template>
					</Column>
					<Column field="venta_numero" header="Nro. Factura" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Nro. Factura</span>
							{{slotProps.data.venta_numero}}
						</template>
					</Column>
					<Column field="venta_fecha" header="Fecha" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							{{slotProps.data.venta_fecha}}
						</template>
					</Column>
					<Column field="venta_vence" header="Vence" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Vence</span>
							{{slotProps.data.venta_vence}}
						</template>
					</Column>
					<Column field="venta_plazo" header="Plazo" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Plazo</span>
							{{slotProps.data.venta_plazo}}
						</template>
					</Column>
					<Column field="venta_total" header="Valor Factura" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Valor Factura</span>
							${{new Intl.NumberFormat().format(slotProps.data.venta_total)}}
							<!-- {{slotProps.data.venta_total}} -->
						</template>
					</Column>
					<Column field="venta_abonos" header="Valor Abonado" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Valor Abonado</span>
							<!-- {{slotProps.data.venta_abonos}} -->
							${{new Intl.NumberFormat().format(slotProps.data.venta_abonos)}}
						</template>
					</Column>
					<Column field="venta_saldo" header="Valor Saldo" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Valor Saldo</span>
							<!-- {{slotProps.data.venta_saldo}} -->
							${{new Intl.NumberFormat().format(slotProps.data.venta_saldo)}}
						</template>
					</Column>
					<Column field="acciones" header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary mr-2" @click="editProduct(slotProps.data)" />
							<!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteProduct(slotProps.data)" /> -->
						</template>
					</Column>
					<template #paginatorstart>
						<!-- <Button type="button" icon="pi pi-refresh" /> -->
						<!-- <pagination v-model="pagination.current_page" ref="pagination" :records="pagination.total" :per-page="pagination.per_page" @paginate="onChangePage" style="background-color: aqua;"/> -->
					</template>
					<template #paginatorend>
						<!-- <Button type="button" icon="pi pi-cloud" /> -->
					</template>
				</DataTable>
				<div>
					<Paginator :v-model:first="pagination.from" :rows="pagination.per_page" :rowsPerPageOptions="[10]" :totalRecords="pagination.total" @page="onChangePage($event)"></Paginator>
				</div>
		</div>
	</div>
</template>

<script>
// import axios from 'axios'
export default {
	data() {
		return {
			message: [],
			customers: [],
			detalle_cartera: [],
			username:null,
			selectgenerador: null,
			generadores: [],
			loader: false,
			email:null,
			//0 a 30 dias
			cuantos_0a30: 0,
			cuantos_0a30_valor: 0,
			detalle_0a30: [],
			//31 a 60 dias
			cuantos_31a60: 0,
			cuantos_31a60_valor: 0,
			detalle_31a60: [],
			//61 a 90 dias
			cuantos_61a90: 0,
			cuantos_61a90_valor: 0,
			detalle_61a90: [],
			//más de 90 dias
			cuantos_mas_90: 0,
			cuantos_mas_90_valor: 0,
			detalle_mas_90: [],
			//sin vencer
			sin_vencer: 0,
			sin_vencer_valor: 0,
			detalle_sin_vencer: [],
			cartera: 0,
			centro_costos: [],
			paginador_mas_90: {},
			paginador_61a90: {},
			paginador_31a60: {},
			paginador_0a30: {},
			pagination: {
				current_page: 0,
				from: 0,
				last_page: 0,
				per_page: 0,
				to: 0,
				total: 0
			},
			count: 0,
			selectedProducts: null,
			items: [
					{label: 'Update', icon: 'pi pi-fw pi-refresh'},
					{label: 'Edit', icon: 'pi pi-fw pi-pencil'}
				],
			ingresoCostumer: false
		}
	},
	mounted(){
		this.cargargeneradorescustomer();
	},
	methods:{
		showSuccess(customer,index) {
			// document.cookie = "NameCustomer="+customer.customer.name;
			// document.cookie = "IdCustomer="+customer.customer.id;
			// document.cookie = "UrlImgCustomer="+customer.customer.url;
			// document.cookie = "IdUsuarioRol="+customer.id;
			// document.cookie = "UserAdminCustomer="+JSON.stringify(customer.useradmincustomer);


			document.cookie = "NameCustomer="+customer.name;
			document.cookie = "IdCustomer="+customer.id_customer;
			document.cookie = "UrlImgCustomer="+customer.url;
			document.cookie = "IdUsuarioRol="+customer.id;
			this.ingresoCostumer = true;
			console.log(index);
			
			// console.log(cookie[cookieName]);
			// console.log(this.$store.getters['infoNavigation/access']);
			
			this.$router.push({ path: this.$store.getters['infoNavigation/rol']+'/inicio'});
		},
		traerindicadores(data){
			if(data){
				// console.log(data);
				this.cargar_empresas(data);
			}else{
				console.log('deseleccionado');
			}
		},
		cargar_empresas(data){
			const cookieName = 'Email';
			const IdRol = 'IdRol';
			this.loader = true;
			this.customers = [];
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			})
			// console.log(cookie[cookieName]);
			// console.log(cookie[IdRol]);
			// console.log(this.$store.getters['infoNavigation/IdRol']);
			this.detalle_cartera = [];
			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];
			this.detalle_0a30 = [];
			this.cuantos_0a30 = 0;
			this.cuantos_0a30_valor = 0;
			this.detalle_31a60 = [];
			this.cuantos_31a60 = 0;
			this.cuantos_31a60_valor = 0;
			this.detalle_61a90 = [];
			
			this.cuantos_61a90 = 0;
			this.cuantos_61a90_valor = 0;
			this.detalle_mas_90 = [];
			this.cuantos_mas_90 = 0;
			this.cuantos_mas_90_valor = 0;
			this.paginador_0a30 = {};
			this.paginador_31a60 = {};
			this.paginador_61a90 = {};
			this.paginador_mas_90 = {};
			this.detalle_sin_vencer = [];
			this.sin_vencer = 0;
			this.sin_vencer_valor = 0;
			var _that = this;
			// EventBus.emit('on-loader');
			var url = 'cargar_pendientes_empresa_transporte_generador?page=1';
			window.api.call('post', url, {email: email,id_rol: id_rol,nit_generador: data}).then( function(response) {
				if(response.status == 200){
					//0 a 30 dias
					_that.cuantos_0a30 = response.data.total_treinta_dias[0].total;
					_that.cuantos_0a30_valor = new Intl.NumberFormat().format(response.data.total_treinta_dias[0].total_valor);

					//31 a 60 dias
					_that.cuantos_31a60 = response.data.total_sesenta_dias[0].total;
					_that.cuantos_31a60_valor = new Intl.NumberFormat().format(response.data.total_sesenta_dias[0].total_valor);

					//61 a 90 dias
					_that.cuantos_61a90 = response.data.total_noventa_dias[0].total;
					_that.cuantos_61a90_valor = new Intl.NumberFormat().format(response.data.total_noventa_dias[0].total_valor);

					//más de 90 dias
					_that.cuantos_mas_90 = response.data.total_mas_de_noventa[0].total;
					_that.cuantos_mas_90_valor = new Intl.NumberFormat().format(response.data.total_mas_de_noventa[0].total_valor);

					//sin vencer
					_that.sin_vencer = response.data.total_sin_vencer[0].total;
					_that.sin_vencer_valor = new Intl.NumberFormat().format(response.data.total_sin_vencer[0].total_valor);

					_that.loader = false;
				}
			}).catch(function (err) {
				console.log(err);
			});
		},
		selectindicador(indicador){
			// console.log(indicador);
			// this.cleartable();
			switch (indicador) {
				case 1:
						this.cartera = 1;
					break;
				case 2:
						this.cartera = 2;
					break;
				case 3:
						this.cartera = 3;
					break;
				case 4:
						this.cartera = 4;
					break;
				case 5:
						this.cartera = 5;
					break;
				default:
					break;
			}
			const page = {};
			page.page = 0;
			this.onChangePage(page);
		},
		cleartable(){
			this.detalle_cartera = [];
			this.pagination.current_page = 0;
				this.pagination.from= 0;
				this.pagination.last_page= 0;
				this.pagination.per_page= 0;
				this.pagination.to= 0;
				this.pagination.total= 0;
		},
		onChangePage(page) {
				const cookieName = 'Email';
				const IdRol = 'IdRol';
				this.loader = true;
				this.customers = [];
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				})
				var email = cookie[cookieName];
				var id_rol = cookie[IdRol];
				
				this.loadDataTable1((page.page+1),this.cartera,email,id_rol);
		},
		loadDataTable1(page,cartera,email,id_rol){
			this.cleartable();
			this.loader = true;
			var _that = this;
                window.api.call('post', '/cargar_cartera_empresa_transporte?&page='+page, {cartera: cartera,email: email,id_rol: id_rol}).then( function(response) {
                    if(response.status == 200){
						// _that.detalle_cartera = response.data.data.data;
						// _that.pagination = response.data.data;
						console.log(response.data.data);
						_that.loader = false;
                    }
                }).catch(function (err) {
                    console.log(err);
					_that.loader = false;
                });
		},
		cargargeneradorescustomer(){
			const cookieName = 'Email';
				const IdRol = 'IdRol';
				this.loader = true;
				this.customers = [];
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				})
				var email = cookie[cookieName];
				var id_rol = cookie[IdRol];
			this.cleartable();
			this.loader = true;
			var _that = this;
                window.api.call('post', '/cargar_generadores_customers', {email: email,id_rol: id_rol}).then( function(response) {
                    if(response.status == 200){
							// _that.detalle_cartera = response.data.data.data;
							_that.generadores = response.data.data;
						//0 a 30 dias
						_that.cuantos_0a30 = response.data.count_treinta_dias;
						_that.cuantos_0a30_valor = new Intl.NumberFormat().format(response.data.acumulador_treinta_dias);

						//31 a 60 dias
						_that.cuantos_31a60 = response.data.count_sesenta_dias;
						_that.cuantos_31a60_valor = new Intl.NumberFormat().format(response.data.acumulador_sesenta_dias);

						//61 a 90 dias
						_that.cuantos_61a90 = response.data.count_noventa_dias;
						_that.cuantos_61a90_valor = new Intl.NumberFormat().format(response.data.acumulador_noventa_dias);

						//más de 90 dias
						_that.cuantos_mas_90 = response.data.count_mas_de_noventa;
						_that.cuantos_mas_90_valor = new Intl.NumberFormat().format(response.data.acumulador_mas_de_noventa);

						//sin vencer
						_that.sin_vencer = response.data.count_sin_vencer;
						_that.sin_vencer_valor = new Intl.NumberFormat().format(response.data.acumulador_sin_vencer);
							// console.log(response.data.data);
							_that.loader = false;
                    }
                }).catch(function (err) {
                    console.log(err);
					_that.loader = false;
                });
		},
		salirCustomer(){
			this.ingresoCostumer = false;
		},
	},
	computed: {
			isRTL() {
				return this.$appState.RTL;
			}
		}
}
</script>

<style scoped>

</style>
